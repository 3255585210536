<template>
  <div class="complaint-distribution-pie">
    <v-chart class="chart" :option="this.chart_data.option" autoresize />
  </div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import calculations from "@/helpers/calculations";
import axios from "axios";
import constants from "@/api/constants";

use([
  CanvasRenderer,
  TitleComponent,
  PieChart,
  TooltipComponent,
  LegendComponent,
]);
export default {
  name: "ComplaintDistributionPie",
  props: ["data", "title"],
  components: {
    AppText,
    VChart,
  },
  provide: {
    [THEME_KEY]: "normal",
  },
  mounted(){
    var results = axios
      .get(constants.hostname + constants.stats.general, 
      {
        headers: {
          "Authorization": "Bearer " + this.$store.state.userData.tokens.access_token
        }
      })
      .then((response) => {
        console.log("Request from ComplaintDistributionPie.vue");

        this.chart_data.option.series[0].data = response.data["values"];
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status == 401){
          this.$store.dispatch('setUserData', {
            tokens: null,
            user: null
          })
          this.$router.push('/login')
        }
      });
  },
  data() {
    return {
      numbers: numbers,
      chart_data: {
        option: {
          title: {
            // text: 'Referer of a Website',
            // subtext: 'Fake Data',
            left: "center",
          },
          label: {
            show: true,
            position: 'top',
            color: "black",
            fontSize: calculations.vhToPx(1.5),
            fontFamily: "Cairo-Bold",
            formatter: function(d) {
              return d.name + " : " + d.data.value;
            }
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "right",
            // Hide
            show: false,
          },

          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "90%",
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              data: [
                {
                  value: 0,
                  name: "جاري المعالجة",
                  itemStyle: { color: "#FFC107" },
                },
                {
                  value: 0,
                  name: "لم تتم المعالجة",
                  itemStyle: { color: "#FF5252" },
                },
                {
                  value: 0,
                  name: "تم المعالجة",
                  itemStyle: { color: "#00BFA6" },
                },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.complaint-distribution-pie {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

const colors = {
    green: [
      {
        start: [0, 102, 0],
        stop: [50, 50, 50],
      },
      {
        start: [70, 70, 70],
        stop: [0, 200, 0],
      },
    ],
    blue: [
      {
        start: [102, 153, 255],
        stop: [10, 10, 10],
      },
      {
        start: [11, 11, 11],
        stop: [103, 154, 254],
      },
    ],
    red: [
      {
        start: [128, 0, 0],
        stop: [50, 50, 50],
      },
      {
        start: [70, 70, 70],
        stop: [128, 0, 0],
      },
    ],
  };
  export default colors;
<template>
<div>
  <div
    class="diagonal-box"
    v-bind:style="{ backgroundColor: this.backgroundColor }"
  >
    <div class="count-title">
      <app-text :text="this.title" :color="this.titleColor"></app-text>
    </div>
    <div class="count-text">
      <app-text :text="numbers.toArabicNumerals(this.count) + '%'" :color="this.countColor"></app-text>
    </div>
  </div>
  <div v-if="includeShadow"  class="shadow" v-bind:style="{backgroundColor: this.shadowColor}"></div>
</div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

export default {
  name: "DiagonalShadowBox",
  props: ["count", "title", "titleColor", "backgroundColor", "countColor", "includeShadow", "shadowColor"],
  components: {
    AppText,
  },
  data() {
    return {
      numbers: numbers,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.diagonal-box {
  background-color: #fff;
  /* border-radius: 10px; */
  /* padding: 20px; */
  height: 8vh;
  width: 100%;
  /* height: 100%; */
  /* margin-top: 20px; */

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.shadow {
      /* 30 degrees shadow */
        background-color: #9c353560;
  /* border-radius: 10px; */
  /* padding: 20px; */
  width: calc(100% - 1.1vh);
  height: 2vh;
  /* margin-top: 20px; */
  
  margin-left: 0.55vh;
    transform: skew(30deg);
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    -ms-transform: skew(30deg);
    box-shadow: 0 0 0.2vh 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 0.2vh 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0.2vh 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 0.2vh 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 0.2vh 0 rgba(0, 0, 0, 0.1);
    /* margin-top: 20px; */
}


.total-count-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.count-text {
  font-size: 0.8vh;
  font-weight: bold;
  color: #173451;
}

.count-title {
  font-size: 0.5vh;
  font-weight: bold;
  color: #173451;
}
</style>

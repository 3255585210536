export default {
    hostname: 'https://admin.my-baghdad.com/',
    login: 'api/login',
    stats: {
        boxes: 'api/stats/boxes',
        general: 'api/stats/general',
        bars: 'api/stats/bars',
        munBoxes : 'api/stats/munBoxes',
    },
    logo : 'storage/settings/January2023/sOkV2jUYJEUzVvlFIoJd.png'
  }
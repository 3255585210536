import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'DashboardView',
    component: DashboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/users/LoginView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((!store.state.userData || !store.state.userData.tokens) && to.meta.requiresAuth) {
    console.log('not logged in')
    next('/login')
  }else{
    console.log('logged in')
    next()
  }

});
export default router

<template>
  <div class="dashboard">
          <!-- Create 3 Rows with items -->
    <div class="flex top-bar">
      <div class="w-full">
        <div class="flex">
          <div
            class="w-full lg:w-1/6 xl:w-1/6 m-3 p-3"
            style="height: 5vh;"
          >
            <circle-progress
              :percent="this.loadingPercent"
              :size="circleSizeFromVhtoPx"
              :show-percent="false"
              empty-color="#fff"
              :is-gradient="false"
              fill-color="black"
              :border-width="circleWidthFromVhtoPx"
            />
          </div>

          <div
            class="flex w-full lg:w-4/6 xl:w-4/6 p-3"
            style="height: 5vh; text-align: center; justify-content: center;"
          >
            <div>
              <img src="/img/logo.png" alt="logo" class="header-logo" />
            </div>
            <div>
              <div>
              <app-text text="منصة بغداد - صوت المواطن" color="black" is-bold="true" />
            </div>
            <div>
              <app-text text="مؤشرات الأداء للمديريات والدوائر البلدية التابعة لأمانة بغداد " color="black" />
            </div>  
            </div>
            <div>
              <img src="/img/logo.png" alt="logo" class="header-logo" />
            </div>
          </div>
          <div
            class="w-full lg:w-1/6 xl:w-1/6 p-3"
            style="height: 5vh;"
          ></div>
        </div>
      </div>
    </div>
    <div class="main-container">

    <div class="flex">
      <div class="w-full">
        <div class="flex flex-wrap">

          <div class="w-full lg:w-3/6 xl:w-3/6 p-3" style="height: 30vh">
            <complaint-distribution-pie
              v-if="complaintDistributionFlipped"
            ></complaint-distribution-pie>
            <complaint-distribution-bar
              v-if="!complaintDistributionFlipped"
            ></complaint-distribution-bar>
          </div>

          <boxes-section/>

        </div>
      </div>
    </div>

    <div class="flex">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-5/5 xl:w-5/5 p-3" style="height: 47vh">
            <bar-label-rotation></bar-label-rotation>
          </div>

        </div>
      </div>
    </div>
  </div>

    <!-- Create bottom 5vh and put datatime inside a flex div-->
    <div class="flex bottom-bar">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/3 xl:w-1/3">
            <!-- Date Time Count -->
            <a class="weatherwidget-io" href="https://forecast7.com/ar/33d3144d36/baghdad/" data-label_1="بغداد" data-label_2="الطقس حاليا" data-font="Droid Arabic Kufi" data-icons="Climacons Animated" data-mode="Current" data-theme="pure" data-basecolor="rgba(255, 255, 255, 0)" >بغداد الطقس حاليا</a>          </div>

          <div class="w-full lg:w-1/3 xl:w-1/3"
            style="justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;"
          >
            <div
              class="footer-text"
            >
              <app-text 
                text="تم تنفيذ المنصة من قبل المديرية العامة للعلاقات والإعلام | أمانة بغداد"
                color="white"
                fontSize="1.9vh"
                >
              </app-text>
            </div>
          </div>


          <div class="w-full lg:w-1/3 xl:w-1/3 date-div">
            <!-- Date Time Count -->
            <div class="datetext">
              <app-text :text="this.datetime" color="#000"> </app-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import CountBox from "@/components/Dashboard/CountBox.vue";
import GradientCountBox from "@/components/Dashboard/GradientCountBox.vue";

import AppText from "@/components/Shared/AppText.vue";
import ComplaintDistributionPie from "@/components/Dashboard/ComplaintDistributionPie.vue";
import ComplaintDistributionBar from "@/components/Dashboard/ComplaintDistributionBar.vue";
import BarLabelRotation from "@/components/Dashboard/BarLabelRotation.vue";
import DiagonalShadowBoxes from "@/components/Dashboard/DiagonalShadowBoxes.vue";
import { mapGetters } from "vuex";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import BoxesSection from "@/components/Dashboard/BoxesSection.vue";
export default {
  name: "DashboardView",
  components: {
    AppText,
    CountBox,
    GradientCountBox,
    ComplaintDistributionPie,
    ComplaintDistributionBar,
    BarLabelRotation,
    DiagonalShadowBoxes,
    CircleProgress,
    BoxesSection
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  data() {
    return {
      // token: this.$store.state.userData.tokens
      complaintDistributionFlipped: false,
      datetime: "",
      userInfo: this.$store.state.userData.user,
      loadingPercent: 0,
      circleSizeFromVhtoPx: 0.05 * window.innerHeight,
      circleWidthFromVhtoPx: 0.01 * window.innerHeight,
      stats: {
        total: {
          count: 1567,
          title: "عدد الشكاوى الكلي",
          titleColor: "#173451",
        },
        active: {
          count: 986798,
          title: "عدد الشكاوى محلولة",
          titleColor: "#173451",
        },
        test: {
          count: 9800,
          title: "عدد الشكاوى غير المحلولة",
          titleColor: "#173451",
        },
      },
    };
  },
  mounted() {
    var js,
      fjs = document.getElementsByTagName("script")[0];
    if (!document.getElementById("weatherwidget-io-js")) {
      js = document.createElement("script");
      js.id = "weatherwidget-io-js";
      js.src = "https://weatherwidget.io/js/widget.min.js";
      fjs.parentNode.insertBefore(js, fjs);
    }

    setInterval(() => {
      if (this.loadingPercent >= 100) {
        this.loadingPercent = 0;
        // Rerender
        this.$forceUpdate();
      }
      this.loadingPercent += 1;
    }, 600);

    // Create Flip Card Counter to Switch between Active and Total every 5 seconds
    setInterval(() => {
      this.complaintDistributionFlipped = !this.complaintDistributionFlipped;
    }, 20000);
    setInterval(() => {
      this.datetime = new Intl.DateTimeFormat("ar", {
        day: "numeric",
        month: "long",
        weekday: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(Date.now());
      // console.log(this.datetime)
    }, 1000);
  },
  methods: {},
};
</script>





<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dashboard {
  text-align: center;
  background-image: url("/public/img/internal-background.jpg");
  height: 100vh;
  /* padding-left: 2vh;
  padding-right: 2vh;
  padding-top: 2vw;
  padding-bottom: 2vw; */
}
.row {

  display: flex;
  flex-direction: row;
}
.bottom-bar {
  height: 8vh;
  /* background-color: #fff; */
  /* color: #fff; */
  font-size: 2vh;
  font-weight: bold;
  /* padding-top: 1vh; */
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0%;
  justify-content: center;
  align-items: center;
}
.datetext {
  /* background-color: #fff; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-container {
  height: 100vh;
  position: fixed;
  width: 97vw;
  left: 0%;
  justify-content: center;
  align-items: center;
}

.header-logo {
  height: 8vh;
  width: 8vh;
  
  margin-left: 2vh;
  margin-right: 2vh;
  display: block;
}
.footer-logo {
  height: 4vh;
  width: 4vh;
  
  margin-left: 1vh;
  margin-right: 1vh;
  display: block;
}

.footer-text {
  background-color: blueviolet;
  padding: 1vh 1.5vw 1vh 1.5vw;
  border-radius: 10px;
  
}

</style>

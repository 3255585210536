<template>
  <div class="complaint-distribution-pie">
    <v-chart 
    class="chart"
     :option="this.chart_data.option"
      autoresize
      
      />
  </div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";

import calculations from "@/helpers/calculations";

import VChart, { THEME_KEY } from "vue-echarts";
import axios from "axios";
import constants from "@/api/constants";

use([
  CanvasRenderer,
  TitleComponent,
  BarChart,
  TooltipComponent,
  LegendComponent,
]);
const config = {
  rotate: 90,
  align: "left",
  verticalAlign: "middle",
  position: "insideBottom",
  distance: 15,
};


const labelOption = {
  show: true,
  position: config.position,
  distance: config.distance,
  align: config.align,
  verticalAlign: config.verticalAlign,
  rotate: config.rotate,
  // formatter: "{c}  {name|{a}}",
  formatter: "{c}",

  fontSize: calculations.vhToPx(2.5),
  fontFamily: "Cairo-Bold",
  rich: {
    name: {
      fontFamily: "Cairo-Bold",
      fontSize: calculations.vhToPx(1),
    },
  },
};

const NUMBER_OF_BARS = 5;

export default {
  name: "BarLabelRotation",
  props: ["data", "title"],
  components: {
    AppText,
    VChart,
  },
  provide: {
    [THEME_KEY]: "normal",
  },

  mounted() {
    this.setActiveBars(false);
    this.fillData();
    
    setInterval(() => {
      this.fillData();
    }, 60000);

    setInterval(() => {
      this.setActiveBars(true);
    }, 10000);
  },

  data() {
    
    return {
      activeStartIndex: 0,
      numbers: numbers,

      allChartData: {
        legend_data: [],
        municipalities: [

        ],
        data: [
          [

          ],
          [

          ],
          [

          ],
        ],
      },
      chart_data: {},
    };
  },
  methods: {

    fillData (){
      var results = axios
      .get(constants.hostname + constants.stats.bars, 
      {
        headers: {
          "Authorization": "Bearer " + this.$store.state.userData.tokens.access_token
        }
      })
      .then((response) => {
        this.allChartData = response.data;
      })
      .catch((error) => {
        console.log(error);
        // If response is 401, go to login page
        if(error.response.status == 401){
          this.$store.dispatch('setUserData', {
            tokens: null,
            user: null
          })
          this.$router.push('/login')
        }
      });
    },

    setActiveBars(iteration) {
      const posList = [
        "left",
        "right",
        "top",
        "bottom",
        "inside",
        "insideTop",
        "insideLeft",
        "insideRight",
        "insideBottom",
        "insideTopLeft",
        "insideTopRight",
        "insideBottomLeft",
        "insideBottomRight",
      ];

      // Set the next NUMBER_OF_BOXES boxes to be active
      if (iteration) {
        this.activeStartIndex =
          this.activeStartIndex + NUMBER_OF_BARS >= this.allChartData.municipalities.length
            ? 0
            : this.activeStartIndex + NUMBER_OF_BARS;
      }
      else{
        this.activeStartIndex = 0;
      }

      this.chart_data = {
        option: {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: this.allChartData.legend_data,
            textStyle: {
              fontFamily: "Cairo-Bold",
              fontSize: calculations.vhToPx(1.5),
            },
          },
          toolbox: {
            show: true,
            orient: "vertical",
            left: "right",
            top: "center",
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar", "stack"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              data: this.allChartData.municipalities.slice(
                this.activeStartIndex,
                this.activeStartIndex + NUMBER_OF_BARS
              ),
              axisLabel: {
                interval: 0,
                rotate: 0,
                fontFamily: "Cairo-Bold",
                fontSize: calculations.vhToPx(1.8),
                color: "black"
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "جاري المعالجه",
              type: "bar",
              barGap: 0,
              label: labelOption,
              emphasis: {
                focus: "series",
              },
              data: this.allChartData.data[0].slice(
                this.activeStartIndex,
                this.activeStartIndex + NUMBER_OF_BARS
              ),
              itemStyle: {
                color: "#FFC107",
              },
            },
            {
              name: "تم العالجة",
              type: "bar",
              label: labelOption,
              emphasis: {
                focus: "series",
              },
              data: this.allChartData.data[1].slice(
                this.activeStartIndex,
                this.activeStartIndex + NUMBER_OF_BARS
              ),              itemStyle: {
                color: "#00BFA6",
              },
            },
            {
              name: "لم يتم العالجة",
              type: "bar",
              label: labelOption,
              emphasis: {
                focus: "series",
              },
              data: this.allChartData.data[2].slice(
                this.activeStartIndex,
                this.activeStartIndex + NUMBER_OF_BARS
              ),              itemStyle: {
                color: "#FF5252",
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.complaint-distribution-pie {
  background-color: #fff;
  border-radius: 10px;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<template>
  <div class="bar-label-rotation">
    <v-chart class="chart" :option="this.chart_data.option" />
  </div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import calculations from "@/helpers/calculations";
import axios from "axios";
import constants from "@/api/constants";

use([
  CanvasRenderer,
  TitleComponent,
  BarChart,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  name: "ComplaintDistributionBar",
  props: ["data", "title"],
  components: {
    AppText,
    VChart,
  },
  provide: {
    [THEME_KEY]: "normal",
  },
  mounted(){
    this.fillData();

    // setInterval(() => {
    //   this.fillData();
    // }, 60000);
  },

  methods:{
    fillData() {
      var results = axios
      .get(constants.hostname + constants.stats.general, 
      {
        headers: {
          "Authorization": "Bearer " + this.$store.state.userData.tokens.access_token
        }
      })
      .then((response) => {
        console.log("Request from ComplaintDistributionBar.vue");
        this.chart_data.option.xAxis.data = response.data["labels"];
        this.chart_data.option.series[0].data = response.data["values"];
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status == 401){
          this.$store.dispatch('setUserData', {
            tokens: null,
            user: null
          })
          this.$router.push('/login')
        }
      });
    }
  },

  data() {
    return {
      numbers: numbers,
      chart_data: {
        option: {
          xAxis: {
            type: "category",
            data: [ "قيد المعالجة","تم المعالجة", "لم يتم المعالجة"],
            axisLabel: {
              interval: 0,
              rotate: 0,
              fontFamily: "Cairo-Bold",
              fontSize: calculations.vhToPx(1.5),
              color: "black",
            },
          },
          yAxis: {
            type: "value",
          },
          label: {
            show: true,
            position: "top",
            color: "black",
            fontSize: calculations.vhToPx(1.5),
            fontFamily: "Cairo-Bold",
            formatter: function (d) {
              return d.data.value;
            },
          },
          series: [
            {
              data: [
                {
                  value: 0,
                  itemStyle: {
                    color: "#FFC107",
                  },
                },
                                {
                  value: 0,
                  itemStyle: {
                    color: "#00BFA6",
                  },
                },
                {
                  value: 0,
                  itemStyle: {
                    color: "#FF5252",
                  },
                },
              ],
              type: "bar",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(180, 180, 180, 0.2)",
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.bar-label-rotation {
  background-color: #fff;
  border-radius: 10px;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<template>
  <div
    class="w-full lg:w-3/6 xl:w-3/6 p-3 grid grid-rows-2 grid-flow-col gap-3"
    style="height: 30vh"
  >
  <gradient-count-box
      :count="stats.notsolved.count"
      :title="stats.notsolved.title"
      backgroundColor="red"
      titleColor="white"
      countColor="black"
    ></gradient-count-box>    

    <count-box
      :count="stats.thisMonthNotSolved.count"
      :title="stats.thisMonthNotSolved.title"
      backgroundColor="red"
      countColor="black"
      titleColor="white"

    ></count-box>
    <gradient-count-box
      :count="stats.solved.count"
      :title="stats.solved.title"
      backgroundColor="green"
      titleColor="white"
      countColor="black"
    ></gradient-count-box>

    <count-box
      :count="stats.thisMonthSolved.count"
      :title="stats.thisMonthSolved.title"
      backgroundColor="green"
      countColor="black"
      titleColor="white"

    ></count-box>

    <gradient-count-box
      :count="stats.total.count"
      :title="stats.total.title"
      backgroundColor="blue"
      titleColor="white"
      countColor="black"
    ></gradient-count-box>
    <count-box
      :count="stats.thisMonthTotal.count"
      :title="stats.thisMonthTotal.title"
      backgroundColor="blue"
      countColor="black"
      titleColor="white"

    ></count-box>

  </div>
</template>



<script>
// @ is an alias to /src
import CountBox from "@/components/Dashboard/CountBox.vue";
import GradientCountBox from "@/components/Dashboard/GradientCountBox.vue";
import axios from "axios";
import constants from "@/api/constants";

export default {
  name: "BoxesSection",
  components: {
    CountBox,
    GradientCountBox,
  },

  mounted() {
    // Send a get request to https://dev.admin.my-baghdad.com/api/stats
    // to get the stats data
    this.fillData();
    
    setInterval(() => {
      this.fillData();
    }, 60000);

    // setInterval(() => {
    //   this.setActiveScores(true);
    // }, 5000);
  },

  data() {
    return {
      activeScoreSectionIndex: 0,
      stats: {
        total: {
          count: "...",
          title: "...",
        },
        solved: {
          count: "...",
          title: "...",
        },
        notsolved: {
          count: "...",
          title: "...",
        },
        thisMonthSolved : {
          count: "...",
          title: "...",
        },
        thisMonthNotSolved : {
          count: "...",
          title: "...",
        },
        thisMonthTotal : {
          count: "...",
          title: "...",
        },
      }
    };
  },
  methods: {

    fillData (){
      var results = axios
      .get(constants.hostname + constants.stats.boxes, 
      {
        headers: {
          "Authorization": "Bearer " + this.$store.state.userData.tokens.access_token
        }
      })
      .then((response) => {
        this.stats = response.data["stats"];
        // this.scores = response.data["scores"];
      })
      .catch((error) => {
        console.log(error);
      });
    },
    // setActiveScores: function (skip) {
    //   // Increment the active score section index by 1 until it reaches the end of the array
    //   // Then reset it to 0

    // this.activeScoreSectionIndex =
    // this.activeScoreSectionIndex + 1 >= this.scores.length
    //     ? 0
    //     : this.activeScoreSectionIndex + 1;
    // },
  },
};
</script>
<template>
  <div>
    <!-- Conditional Text -->
    <div
      v-if="isBold"
      class="app-text-bold"
      :style="{
        fontSize: fontSize,
        textAlign: textAlign,
      }"
    >
      <p v-bind:style="{ color: color }">
            {{ text }}
      </p>

    </div>

    <div
      v-else
      class="app-text-regular"
      :style="{
        fontSize: fontSize,
        textAlign: textAlign,
      }"
    >
      <p v-bind:style="{ color: color }">
            {{ text }}
      </p>
    </div>
  </div>

</template>

<script>

export default {
  name: "AppText",
  props: ["text", "isBold", "fontSize", "color", "textAlign"],
  data() {},
};
</script>

<style scoped>
  .app-text-regular {
    font-size: 2.5vmin;
    font-weight: bold;
    font-family: "Cairo-Regular";
    color: #000000;
  }
  .app-text-bold {
    font-size: 1.5vw;
    font-weight: bold;
    font-family: "Cairo-Bold";
    /* color: #000000; */
  }
</style>
<template>
  <transition name="slide-fade" mode="out-in">

  <div :key="this.count+this.title" class="total-count-box" v-bind:style="{ backgroundColor: this.computedBackgroundColor }">
    <div class="count-title">
      <app-text :text="this.title" :color="this.titleColor" fontSize="2vh"></app-text>
    </div>
    <div class="count-text">
      <app-text :text="numbers.toArabicNumerals(this.count)" :color="this.countColor" ></app-text>
    </div>
  </div>
  </transition>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";
import boxesColors from "@/constants/boxesColors";

export default {
  name: "CountBox",
  props: ["count", "title", "titleColor", "backgroundColor", "countColor"],
  components: {
    AppText,
  },
  computed: {
    computedBackgroundColor() {
      //boxesColors[this.backgroundColor].start is a list of int 
      // get from it the rgb(val1, val2, val3) 
      return `rgb(${boxesColors[this.backgroundColor][0].start.join(",")})`;
    },
  },
  data() {
    return {
      numbers: numbers,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.total-count-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.count-text {
  font-size: 1vh;
  font-weight: bold;
  color: #173451;
  background-color: white;
  width: 5vw;
  border-radius: 10px;
  margin-top: 1vh;
}

.count-title {
  font-size: 1vh;
  font-weight: bold;
  color: #173451;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>

<template>
  <div id="gradientBox">
    <div id="gradient" class="gradient" :style="currGradientStyle">
      <div class="total-count-box">
        <div class="count-title">
          <app-text :text="this.title" :color="this.titleColor"></app-text>
        </div>

        <div class="count-text">
          <app-text
            :text="numbers.toArabicNumerals(this.count)"
            :color="this.countColor"
          ></app-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

import boxesColors from "@/constants/boxesColors";

export default {
  name: "GradientCountBox",
  props: ["count", "title", "titleColor", "backgroundColor", "countColor"],
  components: {
    AppText,
  },


  // mounted () {
  //   setInterval(() => {
  //     this.calcSteps();
  //   }, 3000);
  // },
  computed: {
    colors() {
      return boxesColors[this.backgroundColor];
    },
  },

  data() {
    return {
      numbers: numbers,
      // Function colors check background color and return the right color
      step: 0,
      currIdx: 0,
      nextIdx: 1,
      totalSteps: 228,
      rgbValues: { start: [0, 0, 0], stop: [0, 0, 0] }, // values for gradient
      rgbSteps: { start: [0, 0, 0], stop: [0, 0, 0] }, // steps
      col1: null,
      col2: null,

      currGradientStyle: {
        backgroundImage: null,
      },
    };
  },
  methods: {
    nextStep(n) {
      return n + 1 < this.colors.length ? n + 1 : 0;
    },
    calcStepSize(a, b) {
      return (a - b) / this.totalSteps;
    },
    calcSteps() {
      for (let key in this.rgbValues) {
        if (this.rgbValues) {
          for (var i = 0; i < 3; i++) {
            this.rgbValues[key][i] = this.colors[this.currIdx][key][i];
            this.rgbSteps[key][i] = this.calcStepSize(
              this.colors[this.nextIdx][key][i],
              this.rgbValues[key][i]
            );
            //console.log(this.rgbSteps[key])
          }
        }
      }
    },
    //////////////////////
    updateGradient() {
      for (let key in this.rgbValues) {
        if (this.rgbValues) {
          for (var i = 0; i < 3; i++) {
            this.rgbValues[key][i] += this.rgbSteps[key][i];
          }
        }
      }

      let tCol1 =
        "rgb(" +
        (this.rgbValues.start[0] | 0) +
        "," +
        (this.rgbValues.start[1] | 0) +
        "," +
        (this.rgbValues.start[2] | 0) +
        ")";
      let tCol2 =
        "rgb(" +
        (this.rgbValues.stop[0] | 0) +
        "," +
        (this.rgbValues.stop[1] | 0) +
        "," +
        (this.rgbValues.stop[2] | 0) +
        ")";

      if (tCol1 != this.col1 || tCol2 != this.col2) {
        this.col1 = tCol1;
        this.col2 = tCol2;
        this.currGradientStyle.backgroundImage =
          "linear-gradient(45deg, " + this.col1 + ", " + this.col2 + ")";
      }

      this.step++;
      if (this.step > this.totalSteps) {
        this.step = 0;

        this.currIdx = this.nextStep(this.currIdx);
        this.nextIdx = this.nextStep(this.nextIdx);

        this.calcSteps();
      }

      if (this.currGradientStyle.backgroundImage.indexOf("gradient") != -1) {
        window.requestAnimationFrame(this.updateGradient);
      }
    },
  },
  created() {
    this.calcSteps();
    window.requestAnimationFrame(this.updateGradient);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.total-count-box {
  /* background-color: #fff; */
  border-radius: 10px;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  /* margin: 20px; */
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.count-text {
  font-size: 1vh;
  font-weight: bold;
  color: #173451;
  background-color: white;
  width: 5vw;
  border-radius: 10px;
  margin-top: 2vh;
}

.count-title {
  font-size: 1vh;
  font-weight: bold;
  color: #173451;
}
.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(45deg, rgb(53, 77, 116), rgb(84, 125, 157));
  /* z-index: -1; */
  border-radius: 10px;
}
#gradientBox {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

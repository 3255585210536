import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    userData: {
      tokens: null,
      user: null,
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.tokens.access_token;
    },
    getUserData(state) {
      return state.userData;
    }
  },
  mutations: {
    SET_USER_DATA (state, userData) {
      state.userData = userData
    },
  },
  actions: {
    setUserData: ({ commit }, userData) => {
      commit('SET_USER_DATA', userData)
    },
  },
  modules: {
  },
  plugins: [createPersistedState()],

})

<template>
  <div class="diagonal-boxes">
    <!-- <v-chart class="chart" :option="this.chart_data.option" autoresize /> -->
    <!-- 3 Diagonal boxes on top of each others with backgroundColor blue, green and purple -->
    <div class="flex flex-wrap">
      <!-- For every 3 object in the getActiveBoxes list -->
      <div class="w-full lg:h-10/28 xl:h-10/28 p-0">
        <diagonal-shadow-box
          :backgroundColor="
            this.boxes[activeBoxesStartIndex + 0].backgroundColor
          "
          :includeShadow="this.boxes[activeBoxesStartIndex + 0].includeShadow"
          :shadowColor="this.boxes[activeBoxesStartIndex + 0].shadowColor"
          :title="this.boxes[activeBoxesStartIndex + 0].title"
          :titleColor="this.boxes[activeBoxesStartIndex + 0].titleColor"
          :count="this.boxes[activeBoxesStartIndex + 0].count"
          :countColor="this.boxes[activeBoxesStartIndex + 0].countColor"
        ></diagonal-shadow-box>
      </div>
      <div class="w-full lg:h-10/28 xl:h-10/28 p-0">
        <diagonal-shadow-box
          :backgroundColor="
            this.boxes[activeBoxesStartIndex + 1].backgroundColor
          "
          :includeShadow="this.boxes[activeBoxesStartIndex + 1].includeShadow"
          :shadowColor="this.boxes[activeBoxesStartIndex + 1].shadowColor"
          :title="this.boxes[activeBoxesStartIndex + 1].title"
          :titleColor="this.boxes[activeBoxesStartIndex + 1].titleColor"
          :count="this.boxes[activeBoxesStartIndex + 1].count"
          :countColor="this.boxes[activeBoxesStartIndex + 1].countColor"
        ></diagonal-shadow-box>
      </div>
      <div class="w-full lg:h-8/28 xl:h-8/28 p-0">
        <diagonal-shadow-box v-if="this.boxes.includes(this.boxes[activeBoxesStartIndex + 2])"
          :backgroundColor="
            this.boxes[activeBoxesStartIndex + 2].backgroundColor
          "
          :includeShadow="this.boxes[activeBoxesStartIndex + 2].includeShadow"
          :shadowColor="this.boxes[activeBoxesStartIndex + 1].shadowColor"
          :title="this.boxes[activeBoxesStartIndex + 2].title"
          :titleColor="this.boxes[activeBoxesStartIndex + 2].titleColor"
          :count="this.boxes[activeBoxesStartIndex + 2].count"
          :countColor="this.boxes[activeBoxesStartIndex + 2].countColor"
        ></diagonal-shadow-box>
      </div>
    </div>
  </div>
</template>

<script>
import numbers from "@/helpers/numbers";
import AppText from "@/components/Shared/AppText.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import DiagonalShadowBox from "@/components/Dashboard/DiagonalShadowBox.vue";
import axios from "axios";
import constants from "@/api/constants";

use([
  CanvasRenderer,
  TitleComponent,
  LineChart,
  TooltipComponent,
  LegendComponent,
]);
export default {
  name: "DiagonalShadowBoxes",
  props: ["data", "title"],
  components: {
    AppText,
    VChart,
    DiagonalShadowBox,
  },
  provide: {
    [THEME_KEY]: "normal",
  },
  data() {
    return {
      numbers: numbers,
      activeBoxesStartIndex: 0,
      boxes: [
      {
          backgroundColor: "#46ba9d",
          includeShadow: true,
          shadowColor: "rgba(139, 204, 158, 0.5)",
          title: "...",
          titleColor: "white",
          count: "0",
          countColor: "white",
        },
        {
              backgroundColor: "#55ca77",
              includeShadow: true,
              shadowColor: "rgba(153,50,204, 0.5)",
              title: "...",
              titleColor: "white",
              count: "0",
              countColor: "white",
            },
            {
              backgroundColor: "#945db2",
              title: "...",
              // includeShadow: true,
              titleColor: "white",
              count: "0",
              countColor: "white",
              // shadowColor: "rgba(153,50,204, 0.5)",
            },
      ]
    };
  },
  mounted() {
    this.fillData();
    setInterval(() => {
      this.setActiveBoxes();      
    }, 3000);

    setInterval(() => {
      this.fillData();
    }, 60000);

  },
  methods: {
    fillData (){
      var results = axios
      .get(constants.hostname + constants.stats.munBoxes, 
      {
        headers: {
          "Authorization": "Bearer " + this.$store.state.userData.tokens.access_token
        }
      })
      .then((response) => {
        this.boxes = response.data;
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status == 401){
          this.$store.dispatch('setUserData', {
            tokens: null,
            user: null
          })
          this.$router.push('/login')
        }
      });
    },

    setActiveBoxes() {
      // If the active boxes are the last 3 boxes, set the active boxes to the first 3 boxes
  
      if (this.activeBoxesStartIndex == this.boxes.length - 3 || this.boxes.length < 3 || this.activeBoxesStartIndex > this.boxes.length - 3) {
        this.activeBoxesStartIndex = 0;
      } else {
        this.activeBoxesStartIndex += 3;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.diagonal-boxes {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin: 20px;
  /* margin-top: 0; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* Align everything in center */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

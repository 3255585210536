export default {
    // toArabicNumerals(str) {
    //     // Check if str is of type string
    //     if (typeof str !== 'string') {
    //       str = String(str)
    //     }
    //     return str.split('').map(chr => {
    //       if ('0' <= chr && chr <= '9') {
    //         return String.fromCharCode(+chr + 1632)
    //       }
  
    //       if ('\u0660' <= chr && chr <= '\u0669') {
    //         return chr
    //       }
  
    //       return ''
    //     }).join('')
    //   }
    toArabicNumerals(str) {
        return str
    }
}